import { useReducer } from 'react';

const initialState = {
  isCpcLoading: true,
  showContinueButton: false,
  networkInfo: null,
  deviceFingerprintId: null,
};

// Plaid retry modal scenarios
const retryErrorCodes = ['PAYMENT-1255', 'PAYMENT-9110'];

const cpcEventReducer = (state, action) => {
  switch (action.type) {
    case 'CPC_LOADING':
      return {
        ...state,
        isCpcLoading: action.payload.isLoading,
      };
    case 'CPC_INFO':
      if (action.payload.messageDetail === 'Plaid flow successfully completed.') {
        return {
          ...state,
          showContinueButton: true,
        };
      }
      return state;
    case 'CPC_ERROR':
      if (action.payload.messageDetail === 'Plaid is unavailable, manual CPC ACH form loaded.') {
        return {
          ...state,
          showContinueButton: true,
        };
      }
      return state;
    case 'CPC_FORM_SUBMIT_RESPONSE':
      if (retryErrorCodes.includes(action.payload.cpcData.submissionDetails.psErrorCode)) {
        return {
          ...state,
          showContinueButton: false,
        };
      }
      return state;
    case 'CPC_NETWORK_INFO':
      return {
        ...state,
        networkInfo: action.payload,
      };
    case 'CPC_DEVICE_FINGERPRINT_COMPLETE':
      return {
        ...state,
        // returned on load of the standalone component cpcPageType=DeviceFingerPrintId
        deviceFingerprintId: action.payload.data?.deviceFingerprintId,
      };
    default:
      return state;
  }
};

/**
 * This hook creates handlers for CPC events as needed and they are meant
 * to be passed to and called from the Jump component.
 * The purpose of a handler should be to update the cpcState created by the cpcEventReducer.
 * List of CPC events: https://etwiki.sys.comcast.net/display/MAW/CPC+Events
 *
 * @returns object with cpcState and event handlers
 */
const useCpcEventHandler = () => {
  const [cpcState, dispatch] = useReducer(cpcEventReducer, initialState);

  const cpcLoadingEventHandler = ({ isLoading }) => {
    // We need a timer here for the loading state of the button
    // to match the loading state of the component.
    // See why this is necessary on L#15 of src/hooks/useCpcDispatcher.js.
    setTimeout(() => {
      dispatch({ type: 'CPC_LOADING', payload: { isLoading } });
    }, 1000);
  };

  const cpcInfoEventHandler = (payload) => {
    dispatch({ type: 'CPC_INFO', payload });
  };

  const cpcErrorEventHandler = (payload) => {
    dispatch({ type: 'CPC_ERROR', payload });
  };

  const cpcNetworkInfoHandler = (payload) => {
    dispatch({ type: 'CPC_NETWORK_INFO', payload });
  };

  const cpcDeviceFingerprintHandler = (payload) => {
    dispatch({ type: 'CPC_DEVICE_FINGERPRINT_COMPLETE', payload });
  };

  const cpcFormSubmitResponseEventHandler = (payload) => {
    dispatch({ type: 'CPC_FORM_SUBMIT_RESPONSE', payload });
  };

  return {
    cpcState,
    cpcLoadingEventHandler,
    cpcInfoEventHandler,
    cpcErrorEventHandler,
    cpcNetworkInfoHandler,
    cpcDeviceFingerprintHandler,
    cpcFormSubmitResponseEventHandler,
  };
};

export default useCpcEventHandler;
