import {
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  GET_CONVOY_INDICATORS_SUCCESS,
} from '../actions/account';

const INITIAL_STATE = { loading: true, cached: false };

export default function accountReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ACCOUNT:
      return {
        ...state,
        cached: true,
        loading: true,
        promise: action.payload,
      };
    case GET_ACCOUNT_SUCCESS:
      if (action.payload?.prepaid) {
        window.location.assign('http://my.xfinityprepaid.com/en/litepay');
      }
      return {
        ...state,
        loading: false,
        error: false,
        account: action.payload,
      };
    case GET_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_CONVOY_INDICATORS_SUCCESS:
      // loading and error states for this api are not needed
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
