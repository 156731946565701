// Fetch Payment
export const GET_BILL = 'GET_BILL';
export const REFRESH_BILL = 'REFRESH_BILL';
export const GET_BILL_SUCCESS = 'GET_BILL_SUCCESS';
export const GET_BILL_FAILURE = 'GET_BILL_FAILURE';

// Delinquency
export const GET_DELINQUENCY = 'GET_DELINQUENCY';
export const GET_DELINQUENCY_SUCCESS = 'GET_DELINQUENCY_SUCCESS';

// Enable ecobill
export const TOGGLE_ECOBILL = 'TOGGLE_ECOBILL';
export const TOGGLE_ECOBILL_SUCCESS = 'TOGGLE_ECOBILL_SUCCESS';
export const TOGGLE_ECOBILL_FAILURE = 'TOGGLE_ECOBILL_FAILURE';

// Enable autopay
export const SUBMIT_AUTOPAY_SIGNUP = 'SUBMIT_AUTOPAY_SIGNUP';
export const SUBMIT_AUTOPAY_SIGNUP_SUCCESS = 'SUBMIT_AUTOPAY_SIGNUP_SUCCESS';
export const SUBMIT_AUTOPAY_SIGNUP_FAILURE = 'SUBMIT_AUTOPAY_SIGNUP_FAILURE';
