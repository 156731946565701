import {
  GET_BILL,
  GET_BILL_SUCCESS,
  GET_BILL_FAILURE,
  TOGGLE_ECOBILL,
  TOGGLE_ECOBILL_SUCCESS,
  TOGGLE_ECOBILL_FAILURE,
  SUBMIT_AUTOPAY_SIGNUP,
  SUBMIT_AUTOPAY_SIGNUP_FAILURE,
  GET_DELINQUENCY_SUCCESS,
} from '../actions/bill';

import { DISABLE_AUTOPAY_SUCCESS, ENABLE_AUTOPAY_SUCCESS } from '../actions/autopay';
import { billStateCheck, toggleEcobillValue } from '../helpers/bill';
import paymentMock from '../helpers/paymentMock';

const INITIAL_STATE = { loading: true, cached: false };

export default function billReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BILL:
      return {
        ...state,
        loading: true,
        cached: true,
      };
    case GET_BILL_SUCCESS:
      return (() => {
        const { bill: mockBill } = paymentMock() || {};
        return {
          ...state,
          loading: false,
          error: false,
          bill: { ...action.payload, ...mockBill },
          billStateCheck: billStateCheck({ ...action.payload, ...mockBill }),
        };
      })();
    case GET_BILL_FAILURE:
      return (() => {
        const { bill: mockBill } = paymentMock() || {};
        if (mockBill) {
          return {
            ...state,
            loading: false,
            error: false,
            bill: { ...action.payload, ...mockBill },
            billStateCheck: billStateCheck({ ...action.payload, ...mockBill }),
          };
        }
        return {
          ...state,
          loading: false,
          error: true,
        };
      })();
    case GET_DELINQUENCY_SUCCESS:
      return {
        // loading and error states for this api are not needed
        ...state,
        ...action.payload,
      };
    case ENABLE_AUTOPAY_SUCCESS:
    case DISABLE_AUTOPAY_SUCCESS:
      return {
        ...state,
        bill: {
          ...state.bill,
          summary: {
            ...(state.bill || {}).summary,
            autoPayEnabled: action.type === ENABLE_AUTOPAY_SUCCESS,
            autoPayValid: action.type === ENABLE_AUTOPAY_SUCCESS,
          },
        },
      };
    case TOGGLE_ECOBILL:
      return {
        ...state,
        submitting: true,
        submitError: false,
      };
    case TOGGLE_ECOBILL_SUCCESS:
      return {
        ...state,
        submitting: false,
        bill: {
          ...(state.bill || {}),
          ecoBillDeliveryMethod: {
            ...((state.bill && state.bill.ecoBillDeliveryMethod) || {}),
            deliveryMethod:
              (action.payload && action.payload.deliveryMethod)
              || toggleEcobillValue(state.bill.ecoBillDeliveryMethod.deliveryMethod),
          },
        },
      };
    case TOGGLE_ECOBILL_FAILURE:
      return {
        ...state,
        submitting: false,
        submitError: true,
      };
    case SUBMIT_AUTOPAY_SIGNUP:
      return {
        ...state,
        submitting: true,
        submitError: false,
      };
    case SUBMIT_AUTOPAY_SIGNUP_FAILURE:
      return {
        ...state,
        submitting: false,
        submitError: true,
      };
    default:
      return state;
  }
}
