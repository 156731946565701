import { SSM_STEP_UP } from '../helpers/apis';
import { flagEnabled } from '../helpers/featureFlags';
import { kibanaLog } from '../helpers/logger';
import apiClient from '../middleware/apiClient';

export const GET_STEP_UP_LOADING = 'GET_STEP_UP_LOADING';
export const GET_STEP_UP_PERMIT = 'GET_STEP_UP_PERMIT';
export const GET_STEP_UP_FAILURE = 'GET_STEP_UP_FAILURE';

/**
 * Dispatches an action when the step up api request is pending
 *
 * @returns {Object} An action object.
 */
function dispatchGetStepUpLoading() {
  return {
    type: GET_STEP_UP_LOADING,
  };
}

/**
 * Dispatches an action to handle the step up api 'permit' response
 *
 * @returns {Object} An action object.
 */
function dispatchGetStepUpPermit() {
  return {
    type: GET_STEP_UP_PERMIT,
  };
}


/**
 * Dispatches an action to handle step up api errors and failures.
 *
 * @param {Error} error - The error object which gets added to the value of payload.
 * @returns {Object} An action object.
 */
function dispatchGetStepUpFailure(error) {
  return {
    type: GET_STEP_UP_FAILURE,
    payload: error,
  };
}

export const stepUp = () => async (dispatch) => {
  dispatch(dispatchGetStepUpLoading());

  try {
    const startTime = performance.now();
    const response = await apiClient.fetch(SSM_STEP_UP);
    const endTime = performance.now();

    if (flagEnabled('enhancedCpcLoggingEnabled')) {
      kibanaLog(`step_up_decision_${response.decision}`, {
        duration: (endTime - startTime) / 1000,
      });
    }

    if (response.decision === 'permit') { // load page
      dispatch(dispatchGetStepUpPermit());
    } else if (response.decision === 'deny') { // redirect
      const callbackUrl = `${window.origin}/oauth/callback`;
      const oauthRedirectUrl = new URL(response.authorizationBaseUrl);
      const clientId = window.MAW.env === 'local' ? 'my-account-web-development' : 'my-account-web';

      oauthRedirectUrl.searchParams.set('client_id', clientId);
      oauthRedirectUrl.searchParams.set('response_type', 'code');
      oauthRedirectUrl.searchParams.set('redirect_uri', callbackUrl);
      oauthRedirectUrl.searchParams.set('state', `${window.location.href}?stepup=1`);

      window.location.replace(oauthRedirectUrl.toString());
    } else { // if decision is not explicitly 'permit' or 'deny', is unknown, or is missing
      kibanaLog('Unknown response from Step Up API');
      throw new Error('Unknown response from Step Up API');
    }
  } catch (error) {
    dispatch(dispatchGetStepUpFailure(error));
  }
};
