import {
  GET_CHANNEL_DATA,
  GET_CHANNEL_DATA_SUCCESS,
  GET_CHANNEL_DATA_FAILURE,
  STORE_ENHANCED_TRANSACTION_DATA,
} from '../actions/cpc';

import { keyedReducer } from '../helpers/redux';

export default keyedReducer({
  channelData: null,
  enhancedTransactionData: {
    // CPC_DEVICE_FINGERPRINT_COMPLETE -- on load of review pages when using a stored payment method
    // or CPC_FORM_SUBMIT_RESPONSE -- on submit of the cpc pages when adding a new payment method
    deviceFingerprintId: '',
    // CPC_NETWORK_INFO
    onComcastNetwork: '',
    ipAddress: '',
    countryOfOrigin: '',
    operatingSystem: '',
    browser: '',
    // derived from delinquency api
    accountCollectionState: '',
    // derived from macaroon
    identityUserType: '',
    // derived from indicators api
    accountMdu: false,
    accountCustomerClass: 'RESIDENTIAL_CABLE',
  },
  loading: false,
  promise: null,
  error: null,
}, {
  [GET_CHANNEL_DATA]: (state, { payload }) => ({
    ...state,
    loading: true,
    promise: payload,
  }),
  [GET_CHANNEL_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    channelData: payload,
  }),
  [GET_CHANNEL_DATA_FAILURE]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload,
    promise: null,
  }),
  [STORE_ENHANCED_TRANSACTION_DATA]: (state, { payload }) => ({
    ...state,
    enhancedTransactionData: {
      ...state.enhancedTransactionData,
      ...payload,
    },
  }),
});
