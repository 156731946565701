/* eslint-disable import/prefer-default-export */
import { getIdentityAndAccountParams } from '../helpers/account';
import apiClient from '../middleware/apiClient';

export const GET_CHANNEL_DATA = 'GET_CHANNEL_DATA';
export const GET_CHANNEL_DATA_SUCCESS = 'GET_CHANNEL_DATA_SUCCESS';
export const GET_CHANNEL_DATA_FAILURE = 'GET_CHANNEL_DATA_FAILURE';

export const STORE_ENHANCED_TRANSACTION_DATA = 'STORE_ENHANCED_TRANSACTION_DATA';

export const getChannelData = () => async (dispatch, getState) => {
  const { promise } = getState();
  if (promise) return promise;

  try {
    const response = await dispatch({
      type: GET_CHANNEL_DATA,
      payload: apiClient.fetch('/apis/channel-data'),
    }).payload;
    return dispatch({
      type: GET_CHANNEL_DATA_SUCCESS,
      payload: response,
    }).payload;
  } catch (error) {
    dispatch({
      type: GET_CHANNEL_DATA_FAILURE,
      payload: error,
    });
    throw error;
  }
};

const dispatchStoreEnhancedTransactionData = data => ({
  type: STORE_ENHANCED_TRANSACTION_DATA,
  payload: data,
});

export const storeEnhancedTransactionData = data => (dispatch, getState) => {
  const {
    account: { indicators = {} } = {},
    auth: { macaroon = {} } = {},
    bill: { delinquency = {} } = {},
  } = getState();

  const identityAndAccountData = getIdentityAndAccountParams(macaroon, delinquency);
  const accountMdu = indicators?.connectedBuildingIndicator?.status === 'TRUE';

  dispatch(dispatchStoreEnhancedTransactionData({
    ...identityAndAccountData,
    ...data,
    accountMdu,
  }));
};
