import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { joinClass } from '../../helpers/component';
import PaymentSummary from '../../components/PaymentSummary';
import PaymentMessage from '../../components/PaymentMessage';
import PageTitle from '../../components/PageTitle';

import { sendChannelTracking } from '../../actions/channelTracking';
import {
  SCHEDULED_PAYMENT_CONFIRM,
} from '../../helpers/routes';
import { kibanaLog } from '../../helpers/logger';
import { getTransactionMetadata } from '../../helpers/payments';
import Jump from '../Jump';
import { txnDataPaymentEnabled } from '../../helpers/adobeFlags';

const ScheduledPaymentReview = ({
  handleUpdateScheduledPayment,
  history,
  handleSetSuccess,
  review,
  isHarness,
  submitting,
  bill,
  payment,
  handleSendChannelTracking,
  transactionMetadata,
}) => {
  const [pageLoadedTime] = React.useState(Date.now());
  const onFormSubmit = (e) => {
    e.preventDefault();

    kibanaLog('payment_form_submit', {
      form: 'Scheduled/Review',
    });

    return handleUpdateScheduledPayment({ ...review, transactionMetadata }).then(() => {
      handleSetSuccess();
      if (payment.promisedToPay) {
        handleSendChannelTracking({ id: 'P2P_MODIFY_COMPLETED', interactionType: 'BILLING_PAYMENTS' });
      }
      return history.push(SCHEDULED_PAYMENT_CONFIRM);
    }).catch(() => {
      if (payment.promisedToPay) {
        handleSendChannelTracking({ id: 'P2P_MODIFY_FAILED', interactionType: 'BILLING_PAYMENTS' });
      }
      history.goBack();
    });
  };

  const onSubmitClick = (e) => {
    const { deviceFingerprintId } = transactionMetadata;

    const msSincePageLoad = Date.now() - pageLoadedTime;
    // takes around 6-8 seconds total from when the page loaded to receive the data
    const timer = 8000 - msSincePageLoad;
    const waitForTransactionData = () => new Promise(resolve => setTimeout(resolve, timer));

    e.preventDefault();

    // user tries to click submit before we received the deviceFingerprintId from CPC
    if (!deviceFingerprintId) {
      kibanaLog('Clicked submit before receiving CPC enhanced transaction data', { secondsSincePageLoad: msSincePageLoad / 1000 });
      waitForTransactionData().then(() => {
        onFormSubmit(e);
      });
    } else {
      onFormSubmit(e);
    }
  };

  return (
    <>
      {txnDataPaymentEnabled && (
        // This page would never be proceeded a page that has CPC so we don't need to
        // check if deviceFingerPrintId was already created previously
        // This loads CPC's "standalone" component - there is no UI
        <Jump cpcPageType="DeviceFingerPrintId" />
      )}
      <form
        onSubmit={txnDataPaymentEnabled ? onSubmitClick : onFormSubmit}
        noValidate
      >
        <PageTitle>Review your changes</PageTitle>

        <div className="payment-section">
          <PaymentSummary summary={review} />
          <PaymentMessage bill={bill} payment={review} type="review" />
        </div>

        <div className="action action--right pt6">
          <div className="action__item">
            <button
              className={joinClass('button button--primary', submitting && 'is-loading')}
              type="submit"
              disabled={submitting}
            >
              Submit Changes
            </button>
          </div>
          {!isHarness && (
            <div className="action__item">
              <button className="button" type="button" onClick={history.goBack}>
                Back
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const pmtId = ownProps.match.params.id;
  const {
    bill: { bill: { summary: { balanceDue } } },
    cpc: { enhancedTransactionData },
    scheduledPayments: { payments },
  } = state;

  const payment = payments.find(item => item.id === pmtId) || {};
  const transactionMetadata = getTransactionMetadata({ balanceDue, enhancedTransactionData });

  return {
    payment,
    transactionMetadata,
  };
};

const mapDispatchToProps = dispatch => ({
  handleSendChannelTracking: bindActionCreators(sendChannelTracking, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPaymentReview);
